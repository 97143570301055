import styled from "styled-components"
import media from "styled-media-query"

export const CommentsWrapper = styled.section`
  margin: auto;
  max-width: 70rem;
  padding: 2rem;

  ${media.lessThan("large")`
    padding: 3rem 1.4rem 0;
    max-width: 100%;
  `}

  /*重新定义为传播者*/
  iframe[src*='ads-iframe'] {
    display: none;
  }

  iframe[src*="ads-iframe"] {
    ${media.lessThan("large")`
    padding: 3rem 1.4rem 0;
    max-width: 100%;
  `}
  }

  #disqus_thread {
    a {
      color: var(--hover) !important;
    }
  }
`

export const CommentsTitle = styled.h3`
  color: var(--colorLettersComments);
  font-size: 1.8rem;
  text-align: center;
  font-weight: 700;
  padding-bottom: 2rem;

  ${media.lessThan("large")`
    font-size: 1.375rem;
  `}
`
